import React from "react"
import createReactClass from "create-react-class"
import * as Client from "./body/client"
import * as Util from "./body/util"
const getAntiForgeryToken = Util.getAntiForgeryToken
const getRefer = Util.getRefer
const getAdId = Util.getAdId
import {Input} from "./body/placeholder"
import assign from "object-assign"
import {ContinueWithGoogle, ContinueWithClever} from "shared/oauth"
import {validateEmail, validateComplexPassword} from "util/validate"
import * as url from "util/url"
import {PrimaryButton, TextButton2} from "../../../../components/buttons"
import {Label} from "../../../../components/inputs"

const Constants = {
  EMAIL_LENGTH: 254,
  PASSWORD_LENGTH: 128,
  FIRST_NAME_LENGTH: 40,
  LAST_NAME_LENGTH: 40
}

const validationErrors = {
  INVALID_EMAIL: {
    text: function (errors) {
      const email = errors["email"]
      return function () {
        return email + " is an invalid email address."
      }
    }
  },
  EMAIL_NOT_UNIQUE: {
    text: function (errors) {
      return errors["email"] + " is already being used."
    }
  },
  INVALID_PASSWORD: {
    text: function () {
      return "Your password must have 8 characters including an upper case letter and a number or symbol."
    }
  }
}

const getNextErrorText = (errors) => {
  const ks = Object.keys(errors)
  let i = 0
  for (i = 0; i < ks.length; i++) {
    if (errors[ks[i]]) {
      break
    }
  }
  return errors[ks[i]]
}

const hasErrors = (errors) => {
  const text = getNextErrorText(errors)
  return text !== undefined && text !== null
}

const ErrorView = ({errors, details}) => {
  let view = null
  if (hasErrors(errors)) {
    const text = getNextErrorText(errors)
    view = <span className="text-danger-1 text-sm">{typeof text === "function" ? text(details) : text}</span>
  }
  return <div className="my-error">{view}</div>
}

const formatError = (details, error) => {
  return error.text(details)
}

const getUrlErrors = () => {
  const emailError = url.getParameterByName("email-taken")
  return emailError ? {email: formatError({email: emailError}, validationErrors.EMAIL_NOT_UNIQUE)} : {}
}

// Crazy mutable function that relies on
// pointers. Don't try too hard to understand.
const setIn = (m, ks, v) => {
  let obj = m
  let i = 0
  for (i = 0; i < ks.length - 1; i++) {
    m = m[ks[i]]
  }
  m[ks[i]] = v
  return obj
}

const passwordValid = (password) => validateComplexPassword(password)

export default createReactClass({
  disabled() {
    return this.state.submittingForm || !this.valid()
  },
  valid() {
    return this.state.emailValidated && this.state.passwordValidated && this.state.firstNameValidated && this.state.lastNameValidated
  },
  imSetState(f) {
    return this.setState((state) => {
      return f(state)
    })
  },
  imSetStateVal(k, v) {
    this.imSetState((state) => {
      if (typeof k === "string") {
        const nv = state
        nv[k] = v
        return nv
      } else {
        return setIn(state, k, v)
      }
    })
  },
  imDeleteKey(k) {
    this.imSetState((state) => {
      if (typeof k === "string") {
        const nv = state
        delete nv[k]
        return nv
      } else {
        return setIn(state, k, undefined)
      }
    })
  },
  imSetStateMerge(m) {
    this.imSetState((state) => {
      return assign({}, state, m)
    })
  },
  defaultState: {
    emailValidated: false,
    passwordValidated: false,
    firstNameValidated: false,
    lastNameValidated: false,
    email: "",
    password: "",
    firstName: "",
    lastName: "",
    checkingEmailUnique: false,
    submittingForm: false
  },
  getInitialState() {
    return assign({}, this.defaultState, {
      antiForgeryToken: getAntiForgeryToken(),
      refer: getRefer(),
      adid: getAdId(),
      errors: getUrlErrors()
    })
  },
  onEmailChange(e) {
    const email = e.target.value.substr(0, Constants.EMAIL_LENGTH).toLowerCase()
    this.imSetStateMerge({
      email: email,
      emailValidated: validateEmail(email)
    })
  },
  onEmailBlur() {
    const state = this.state
    const email = state.email
    if (email === "") {
      this.imSetStateVal("emailValidated", false)
    } else if (validateEmail(email)) {
      const self = this
      self.imSetStateVal("checkingEmailUnique", true)
      Client.validateUniqueUsername(email, (err, res) => {
        if (res) {
          const valid = res.body
          self.imSetStateMerge({
            emailValidated: valid,
            checkingEmailUnique: false
          })
          if (valid) {
            self.imDeleteKey(["errors", "email"])
          } else {
            self.imSetStateVal(["errors", "email"], formatError(state, validationErrors.EMAIL_NOT_UNIQUE))
          }
        }
      })
    } else {
      this.imSetStateMerge({emailValidated: false})
      this.imSetStateVal(["errors", "email"], formatError(this.state, validationErrors.INVALID_EMAIL))
    }
  },
  onPasswordChange(e) {
    const password = e.target.value

    this.imSetStateMerge({
      password: password,
      passwordValidated: passwordValid(password)
    })

    if (passwordValid(password)) {
      this.imSetStateVal("passwordValidated", true)
      this.imDeleteKey(["errors", "password"])
    } else {
      this.imSetStateVal("passwordValidated", false)
      this.imSetStateVal(["errors", "password"], formatError(this.state, validationErrors.INVALID_PASSWORD))
    }
  },
  onFirstNameChange(e) {
    const firstName = e.target.value
    this.imSetStateMerge({
      firstName: firstName,
      firstNameValidated: firstName !== ""
    })
  },
  onLastNameChange(e) {
    const lastName = e.target.value
    this.imSetStateMerge({
      lastName: lastName,
      lastNameValidated: lastName !== ""
    })
  },
  handleServerError(error) {
    if (error) {
      switch (error) {
        case "email-not-unique":
          this.imSetStateMerge({emailValidated: false})
          this.imSetStateVal(["errors", "email"], formatError(this.state, validationErrors.EMAIL_NOT_UNIQUE))
          return
      }
    } else {
      throw new Error(`Unhandled error on teacher signup: no error code`)
    }
  },
  submitLoginForm(a, resp = {}) {
    const {status, body} = resp

    switch (status) {
      case 200: {
        this.refs.loginForm.submit()
        return
      }
      case 422: {
        this.handleServerError(body.error)
        this.imSetStateVal("submittingForm", false)
        return
      }
    }
  },
  onSubmit(e) {
    this.imSetStateVal("submittingForm", true)
    const params = {
      "email": this.state.email,
      "password": this.state.password,
      "first_name": this.state.firstName,
      "last_name": this.state.lastName,
      "ad_id": this.state.adid,
      "refer": this.state.refer,
      "__anti-forgery-token": "antiForgeryToken"
    }
    Client.submit(params, this.submitLoginForm)
    e.stopPropagation()
    e.preventDefault()
  },
  render() {
    const disabled = this.disabled() ? "disabled" : ""
    const email = this.state.email
    const password = this.state.password
    const firstName = this.state.firstName
    const lastName = this.state.lastName

    return (
      <div id="teacher-signup" className="grid gap-y-6">
        <div className="grid gap-y-6">
          <ContinueWithGoogle userType="Teacher" />
          <ContinueWithClever actionType="signup" />
          <div className="overline-sm">OR</div>
        </div>
        <form className="grid gap-y-6" method="POST" action="/teachers/signup" onSubmit={this.onSubmit}>
          <div className="grid gap-y-6">
            <label>
              <Label>First Name</Label>
              <Input className="first-name" type="text" name="first_name" onChange={this.onFirstNameChange} maxLength={Constants.FIRST_NAME_LENGTH} value={firstName} />
            </label>
            <label>
              <Label>Last Name</Label>
              <Input type="text" name="last_name" onChange={this.onLastNameChange} maxLength={Constants.LAST_NAME_LENGTH} value={lastName} />
            </label>
            <div className="email">
              <label>
                <Label>Email</Label>
                <Input type="text" name="email" onChange={this.onEmailChange} onBlur={this.onEmailBlur} maxLength={Constants.EMAIL_LENGTH} value={email} />
              </label>
            </div>
            <label>
              <Label>Password</Label>
              <Input type="password" name="password" onChange={this.onPasswordChange} maxLength={Constants.PASSWORD_LENGTH} value={password} />
            </label>
          </div>
          <ErrorView errors={this.state.errors} email={this.state.email} />
          <PrimaryButton type="submit" className="w-full" disabled={disabled} onClick={this.onSubmit}>
            {this.state.submittingForm ? "Creating account..." : "Get Started"}
          </PrimaryButton>
        </form>
        <div className="">
          <TextButton2 href="/auth#/login">I already have an account.</TextButton2>
        </div>
        <form className="absolute" method="POST" action="/users/login/legacy" ref="loginForm">
          <input type="hidden" name="username" value={email} />
          <input type="hidden" name="password" value={password} />
        </form>
      </div>
    )
  }
})
